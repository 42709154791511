<template>

        <div class="card" :style="deslocamentoDoChat">
            <div class="card-header bg-secondary text-white" :class="{alerta: alerta}" @click="mostrarEEsconder">

                <i v-if="!conversa.grupo" class="fa fa-circle" :class="[ online ? 'online' : 'offline' ]"></i>

                <b>{{ titulo }}</b>

                <button @click.stop="fecharConversa" type="button" class="close"><span>&times;</span></button>
            </div>

            <div ref="card-body" class="card-body" :class="[ mostrarEmojis ? 'card-body-com-emoji' : '' ]" v-show="mostrar" @click="desabilitarAlerta">

                <div class="text-center" v-if="temMaisMensagens" style="margin-bottom: 20px;">
                    <button class="btn btn-xs btn-info" @click="carregarMaisMensagens" :disabled="carregandoMensagens"><i class="fa fa-arrow-up" :class="{'fa-spin': carregandoMensagens}"></i> Carregar mais</button>
                </div>

                <div class="mensagens">
                    <div v-for="mensagem in conversa.mensagens" class="clearfix">
                        <blockquote :class="[mensagem.remetente_id == usuario_id ? ['me', 'pull-right'] : ['you', 'pull-left', mensagem.visualizada ? 'animated mensagem-visualizada' : 'animated mensagem-nao-visualizada']]">
                            <span class="data-e-hora">{{ mensagem.data_e_hora }}</span>
                            <br>
                            <p class="mensagem" v-html="formatarTexto(mensagem.texto)"></p>
                        </blockquote>
                    </div>
                </div>
            </div>

            <div class="card-footer" v-show="mostrar" @click="desabilitarAlerta">
                <div v-if="mostrarEmojis">
                    <hr style="margin: 0px">
                    <button @click.stop="mostrarEmojis = false" type="button" class="close"><span>&times;</span></button>
                    <br>
                    <p class="emojis">
                        <img v-for="emoji in emojis"
                            class="emojis"
                            @click="mensagem += emoji[0]"
                            :alt="emoji[0]"
                            :src="'/emojis/'+emoji[1]+'.svg'" />
                    </p>
                </div>

                <span class="pull-right" style="display: inline-block;width: 20px; height: 20px;" @click="alternarSom">
                    <i class="fa" :class="[somAtivado ? 'fa-volume-up' : 'fa-volume-off']" ></i>
                </span>
                <br>

                <div class="input-group">
                    <!-- <input type="text" class="form-control" placeholder="Search for..."> -->
                    <textarea ref="campoMensagem" style="border-color: #aaa" class="form-control" name="name" v-model="mensagem" @input="desabilitarAlerta" @keyup.enter="enviarMensagem"></textarea>

                    <span class="btn-group">
                        <button class="btn btn-warning" type="button" style="background-image: url(/emojis/1f600.svg);background-repeat: no-repeat;background-position: center;width: 35px" @click="mostrarEmojis = true">
                        </button>
                        <button class="btn btn-primary" type="button" @click="enviarMensagem"><b><i class="fas fa-paper-plane"></i></b></button>
                    </span>
                </div>

            </div>
        </div>
</template>

<script>

    export default {
        props: ['conversa', 'contatos', 'usuario_id', 'index', 'chat_chave_de_sessao', 'chat_chave_criptografia'],

        data () {
            return {
                mostrar: true,
                alerta: this.conversa.alerta,
                mensagem: '',
                sound: null,
                somAtivado: true,
                temMaisMensagens: false,
                carregandoMensagens: false,
                mostrarEmojis: false,
                emojis: window.emojis,
            }
        },

        mounted: function () {
            var self = this;

            this.$refs.campoMensagem.focus();
            this.restaurarEstadoDaConversa();

            this.sound = new Audio('/song/solemn.ogg');

            this.buscarMensagens(() => {
                self.scrollToEnd();
            });

            this.baixarInfoDaConversa();
        },

        watch: {
            'conversa.mensagens': function (newVal, oldVal) {
                this.scrollToEnd()
            }
        },

        computed: {
            deslocamentoDoChat () {
                var right = ((this.index + 1) * 295) + 20;

                return 'right: ' + right + 'px';
            },

            titulo () {

                if (this.conversa.grupo) {
                    return this.conversa.titulo;
                }

                var titulo = '';
                this.conversa.participantes.forEach((participante) => {
                    if (participante.id != self.usuario_id) {
                        titulo = participante.nome;
                        return false;
                    }
                });
                return titulo
            },

            online () {
                if (this.conversa.grupo) {
                    return;
                }

                let self = this;
                var contato = null;

                this.conversa.participantes.forEach((participante) => {
                    if (participante.id != self.usuario_id) {
                        contato = participante;
                        return false;
                    }
                });

                contato = _.find(this.contatos, {id: contato.id});

                if (contato) {
                    return contato.online;
                }

                return false;
            },

            chaveDeSessaoConversa () {
                return this.chat_chave_de_sessao + '_' + md5(this.conversa.id);
            }
        },

        methods: {

            formatarTexto (texto) {
                return window.textToEmoji(texto);
            },

            baixarInfoDaConversa () {
                let self = this;

                axios.get('/chat/conversas/'+this.conversa.id)
                    .then(function (response) {
                        self.conversa.participantes = response.data.participantes;
                        self.conversa.titulo;
                    })
            },

            carregarMaisMensagens () {
                this.buscarMensagens();
            },

            buscarMensagens (callback) {
                var self = this;
                var url =  '/chat/conversas/'+this.conversa.id+'/mensagens';

                if (this.conversa.mensagens.length > 0) {
                    url += '?ultimo_id=' + this.conversa.mensagens[0].id;
                }

                this.carregandoMensagens = true;

                axios.get(url)
                    .then((response) => {

                        self.temMaisMensagens = response.data.tem_mais;
                        self.conversa.mensagens = response.data.mensagens.concat(
                            self.conversa.mensagens
                        );
                    })
                    .finally(function () {
                        self.carregandoMensagens = false;

                        if(callback)
                            callback();
                    });
            },

            enviarMensagem () {

                this.mostrarEmojis = false;

                if(this.mensagem.trim() == '') {
                    this.mensagem = '';
                    return;
                }

                axios.post('/chat/conversas/'+this.conversa.id+'/mensagens', {
                    texto: this.mensagem.trim()
                });

                this.mensagem = '';
            },

            mostrarEEsconder () {
                this.mostrar = ! this.mostrar;
                this.alerta = false;
                this.scrollToEnd();

                if(!this.mostrar)
                    this.marcarMensagensComoVisualizadas();

                this.salvarEstadoDaConversa();
            },

            scrollToEnd: function() {
                var container = this.$el.querySelector(".card-body");
                setTimeout(function () {
                    container.scrollTop = container.scrollHeight;
                }, 50);
            },

            fecharConversa () {
                this.marcarMensagensComoVisualizadas();
                this.removerEstadoDaConversa();

                this.$emit('fechar', this.conversa, this.index);

            },

            desabilitarAlerta () {

                if(this.alerta)
                    this.marcarMensagensComoVisualizadas();

                this.alerta = false;

            },

            alternarSom() {
                this.somAtivado = !this.somAtivado;
                this.salvarEstadoDaConversa();
            },

            salvarEstadoDaConversa () {
                var estado = JSON.stringify({
                    mostrar: this.mostrar,
                    somAtivado: this.somAtivado
                });

                var hash = CryptoJS.AES.encrypt(estado, this.chat_chave_criptografia).toString()
                localStorage.setItem(this.chaveDeSessaoConversa, hash);
            },

            restaurarEstadoDaConversa () {
                var estado = localStorage.getItem(this.chaveDeSessaoConversa);


                if(estado) {
                    estado = JSON.parse(decrypt(estado, this.chat_chave_criptografia));

                    this.mostrar = estado.mostrar;
                    this.somAtivado = estado.somAtivado;
                } else {
                    this.salvarEstadoDaConversa();
                }
            },

            removerEstadoDaConversa () {
                localStorage.removeItem(this.chaveDeSessaoConversa);
            },

            marcarMensagensComoVisualizadas() {

                // var idMensagens = _.chain(this.mensagens)
                //     .filter({'visualizada': false, 'remetente': 'you'})
                //     .map((mensagem) => {
                //         mensagem.visualizada = true;
                //         return mensagem.id;
                //     })
                //     .value();
                //
                // axios.put('/chat/mensagens/marcar-visualizadas', {
                //     contato_id: this.conversa.contato.id,
                //     ids_mensagens: idMensagens
                // }).then((response) => {});
            }
        }
    }
</script>

<style scoped>

    /* p.mensagem {
        font-size
    } */


    p.emojis {
        font-size: 24px;
        height: 130px;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        overflow-y: auto;
    }

    img.emojis {
        padding-left: 2px;
        padding-right: 2px;
        cursor: pointer;
    }

    .card {
        width: 280px;
        position: fixed;
        bottom: 0;
        box-sizing: border-box;
        z-index: 10;
    }

    .card-header {
        cursor: pointer;
        font-size: 13px;
    }

    .card-header.alerta {
        animation: colors 1s infinite;
    }

    @keyframes colors {
        from {background-color: #3097D1;}
        to {background-color: #146796;}
    }

    .card-body {
        height: 260px;
        overflow-y: auto;
    }

    .card-body-com-emoji {
        height: 130px;
        overflow-y: auto;
    }

    .card-body::-webkit-scrollbar-track
    {
    	background-color: #F5F5F5;
    }

    .card-body::-webkit-scrollbar
    {
    	width: 5px;
    }

    .card-body::-webkit-scrollbar-thumb
    {
    	background-color: #3097D1;
    }

    .card-body .mensagens {
        margin-bottom: 5px;
    }

    .card-footer {
        padding: 2px;
        border-top: none;
    }

    .card-footer textarea {
        height: 50px;
    }

    .card-footer .btn {
        padding: 13px 12px;
    }

    .mensagens .data-e-hora {
        font-size: 8px;
    }

    .me {
        font-size: 14px;
        margin: 1px 0;
        border-right: 3px solid #60DF88 !important;
        padding: 5px;
        background-color: #EFFFF211;
    }

    .you {
        font-size: 14px;
        margin: 1px 0;
        border-left: 3px solid rgb(255, 112, 0) !important;
        padding: 5px;
        padding-left:10px;
        background-color: #F7F8D811;
    }

    .mensagem-visualizada {

    }

    .mensagem-nao-visualizada {
        /*animation-name: shake;*/
        border-left: 3px solid rgb(71, 151, 234) !important;
    }

    .fa-circle.online {
        color: #55da55;
    }

    .fa-circle.offline {
        color: #cac7c7;
    }
</style>
