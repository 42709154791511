<template>

    <div class="row">
        <div class="col-md-12">
            <div class="form-group form-group-md">
                <div class="input-group">
                    <a :href="`/merenda/bi/gerar-planilha/gasto-por-escola-detalhado?ano=${this.filtros.ano}&mes=${this.filtros.mes}`">
                        <label class="btn btn-primary btn-md">Gerar planilha</label>
                    </a>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-4 offset-md-3">
                    <div class="form-group form-group-md">
                        <label class="label-control">Mês</label>
                        <div class="input-group">
                            <span class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="far fa-calendar-alt"></i>
                                </span>
                            </span>
                            <select ref="mes"
                                    v-model="filtros.mes"
                                    class="form-control text-center"
                                    title="Mês"
                                    style="padding: 0;">
                                <option value="">Todos os meses do ano</option>
                                <option v-bind:key="mes" v-for="(mes, index) in meses" :value="index+1" v-html="mes">
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group form-group-md">
                        <label class="label-control">Ano</label>
                        <div class="input-group">
                            <span class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="far fa-calendar-alt"></i>
                                </span>
                            </span>
                            <select ref="ano"
                                    v-model="filtros.ano"
                                    class="form-control text-center"
                                    title="Ano"
                                    style="padding: 0;">
                                <option v-bind:key="ano" v-for="ano in anos" :value="ano" v-html="ano">
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <Chart tipo="HorizontalBar" :opcoes="opcoes" :url="url">
            </Chart>
        </div>
    </div>

</template>

<script>

    import Chart from '../Chart.vue';

    export default {

        components: {
            'Chart': Chart,
        },

        data () {
            return {
                filtros: {
                    ano: this.anoAtual(),
                    mes: '',
                },

                opcoes: {
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [{
                            ticks: {
                                callback: function(value, index, values) {
                                    return formatarDinheiro(value, 'R$', 2, ',', '.');
                                }
                            },
                            scaleLabel: {
                                display: true,
                                labelString: 'Gastos (R$)'
                            }
                        }]
                    },
                    layout: {
                        padding: {
                            left: 50,
                            right: 50,
                            top: 20,
                            bottom: 30
                        }
                    },
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem, data) {
                                return 'Gasto: ' + formatarDinheiro(tooltipItem.xLabel, 'R$', 2, ',', '.');
                            }
                        }
                    }
                }
            }
        },

        mounted () {
            // empty
        },

        computed: {
            anos: function () {
                return _.range(2019, this.anoAtual() + 1);
            },

            meses: function () {
                return moment.months();
            },

            url: function () {
                return `/merenda/bi/gasto-por-escola?ano=${this.filtros.ano}&mes=${this.filtros.mes}`;
            }
        },

        methods: {
            anoAtual() {
                return moment().year();
            },

            mesAtual() {
                return moment().month() + 1;
            }
        },

    }
</script>
