$(function () {

    // custom
    $('body').on('keyup', '[data-mask-dinheiro]', function (e) {
        $(this).val(this.value.replace('.',','));
    });

    $('[data-mask]').each(function() {
        $(this).mask($(this).attr("data-mask"));
    });

    $('[data-mask-reverse]').each(function() {
        $(this).mask($(this).attr("data-mask-reverse"), {reverse: true});
    });

    // hora
    $('.data-mask-hora').mask('00:00');
    $('body').on('change', '.data-mask-hora', function () {
        var self = $(this);
        var time = this.value;

        if (time && time.length < 5) {
            if (time.length == 1) {
                time = '0' + time;
            }

            if (time.length == 2) {
                time += ':';
            }

            if (time.length == 3) {
                time += '0';
            }

            if (time.length == 4) {
                time += '0';
            }

            self.val(time);
        }
    });

    // telefone
    var SPMaskBehavior = function (val) {
      return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    },
    spOptions = {
      onKeyPress: function(val, e, field, options) {
          field.mask(SPMaskBehavior.apply({}, arguments), options);
        }
    };

    $('[data-mask-telefone]').each(function() {
        $(this).mask(SPMaskBehavior, spOptions);
    });

    // datepicker
    $('[data-mask-datepicker]').each(function () {
        var valor = this.value;

        $(this).daterangepicker({
                locale: {
                    "format": "DD/MM/YYYY",
                    "applyLabel": "Aplicar",
                    "cancelLabel": "Limpar",
                },
                autoUpdateInput: true,
                singleDatePicker: true,
                autoApply: false,
            })
            .on('apply.daterangepicker', function (ev, picker) {
                $(this).val(picker.startDate.format(picker.locale.format));

                // Adicionado para disparar v-model do vue
                $(this)[0].dispatchEvent(new Event('change'));
            })
            .on('cancel.daterangepicker', function(ev, picker) {
                $(this).val('');

                // Adicionado para disparar v-model do vue
                $(this)[0].dispatchEvent(new Event('change'));
            });

        if (!valor) {
            $(this).val('');
        }
    });

    // daterange
    $('[data-mask-daterange]').each(function () {
        var valor = this.value;

        $(this)
            .daterangepicker({
                locale: {
                    "format": "DD/MM/YYYY",
                    "separator": " - ",
                    "applyLabel": "Aplicar",
                    "cancelLabel": "Limpar",
                    "fromLabel": "de",
                    "toLabel": "para",
                    "customRangeLabel": "personalizado",
                    "daysOfWeek": [
                        "Dom",
                        "Seg",
                        "Ter",
                        "Qua",
                        "Qui",
                        "Sex",
                        "Sab"
                    ],
                    "monthNames": [
                        "Janeiro",
                        "Fevereiro",
                        "Março",
                        "Abril",
                        "Maio",
                        "Junho",
                        "Julho",
                        "Agosto",
                        "Setembro",
                        "Outubro",
                        "Novembro",
                        "Dezembro"
                    ],
                    "firstDay": 1
                }
            })
            .on('cancel.daterangepicker', function(ev, picker) {
                $(this).val('');
            });

        if (!valor) {
            $(this).val('');
        }
    });

});
