
/**
 * form-async.js
 *
 * Script utilizado nos forms async da aplicação
 */

$(function () {

    $('[data-type="async-form"]')
        .on('init', function (e, novasOpcoes) {

        var form = $(novasOpcoes.form);
        var content = form.find('#form-content');
        var alert = form.find('#alert');
        var titulo = form.find('#titulo');

        form.prop('opcoes', novasOpcoes);
        alert.css('display', 'none');
        form.trigger('reset');
        titulo.html(novasOpcoes.titulo);

        if (typeof novasOpcoes.reset === "function") {
            novasOpcoes.reset();
        }

        if (novasOpcoes.method.toLowerCase() == 'post') {
            setTimeout(function () {
                form.find('#slot').find('input:visible:first').focus();
            },100);
        }

        if (form.prop('init')) {
            return;
        }

        form.prop('init', true);

        form.find('[data-action=cancelar]').on('click', function () {
            var opcoes = form.prop('opcoes');

            form.trigger('reset');

            if (typeof opcoes.reset === "function") {
                // opcoes.reset();
            }

            if (typeof opcoes.cancelado === "function") {
                opcoes.cancelado();
            }
        });

        form.submit(function (e) {
            var opcoes = form.prop('opcoes');

            e.preventDefault();

            alert.css('display', 'none');
            content.loading({mensagem: 'Aguarde, salvando ...', loading: true});

            axios({
                    url: opcoes.action,
                    method: opcoes.method,
                    data: form.serialize()
                })
                .then(function (response) {
                    form.trigger('reset');
                    if (typeof opcoes.concluido === "function") {
                        opcoes.concluido(response.data);
                    }

                    if (typeof opcoes.reset === "function") {
                        opcoes.reset();
                    }
                })
                .catch(function (e) {
                    alert.css('display', '');
                    alert.find('.alert-content')
                        .html(exceptionToString(e));
                })
                .finally(function () {
                    form.trigger('reset-button-submit');
                    content.loading(false);
                    setTimeout(function () {
                        form.find('#slot').find('input:visible:first').focus();
                    },100);
                });
        });

    });
});
