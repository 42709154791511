/**
 * custom.js
 *
 * Destina-se à declaração de scripts específicos da aplicação
 */

window.encrypt = function (texto, chave) {
    return CryptoJS.AES.encrypt(texto, chave).toString();
}

window.decrypt = function (textoCifrado, chave) {
    return CryptoJS.AES.decrypt(textoCifrado, chave).toString(CryptoJS.enc.Utf8);
}

window.md5 = function (texto) {
    return CryptoJS.MD5(texto).toString();
}

window.formatarDinheiro = function (valor, simbolo = '', decimais = 2, pontoDecimal = ',', separadorMilhares = '') {
    let valorFormatado = '';

    if (valor != undefined) {
        valorFormatado += parseFloat(valor)
            .toFixed(decimais)
            .replace(/\./g, pontoDecimal)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${separadorMilhares}`);
    }

    if (simbolo) {
        valorFormatado = `${simbolo} ${valorFormatado}`;
    }

    return valorFormatado;
}

$(function () {
    $(window).resize(function() {
        $('.datatable').DataTable().columns.adjust();
        console.log('tamanho mudou');
    });
});

// 2 - aplica to string em exception
window.exceptionToString = function (exception) {
    window.exeception = exception;
    switch(exception.request.status) {
        case 422:
            return _.values(exception.response.data.errors).join('<br>');

        case 403:
        case 401:
            return 'Sem autorização';

        default:
            return exception.response.data.message;
    }
}

// 3 - Eventos de formulários
$('form')
    // Desabilitar RESUBMIT de formulários
    .on('submit',  function (e) {
        var $self   = $(this);
        var $submit = $self.find('[type="submit"]');

        if ($submit.prop('disabled')) {
            e.preventDefault();
            return;
        }

        $submit.prop('tmp-label', $submit.html());
        $submit.prop('disabled', true)
            .html('<i class="fas fa-sync fa-spin"></i> Aguarde ...');
    })
    // Aplica reset no form
    .on('reset', function (e)  {

        // reseta button de submit
        $(this).trigger('reset-button-submit');

        // limpa elementos do formulário
        this.reset();

        // limpa autocomplete
        $(this).find('.app-multiselect').each(function (i, el) {
            let id = $(el).attr('id');
            if (id) {
                app.$emit('event-autocomplete-clear-'+id);
            }
        })
    })
    // Reabilita button de submit
    .on('reset-button-submit', function (e) {
        var $submit = $(this).find('[type="submit"]');
        $submit.prop('disabled', false)
            .html($submit.prop('tmp-label'));
    });

// 4 - Aplica loading sobre um componente html,
// ex.: $(select).loading({mensagem: '', loading: true }), $(select).loading(true), $(select).loading(false)
$.fn.loading = function (opcoes)
{
    var $elemento = this;
    if(typeof(opcoes)=='object') {
        var loading  = opcoes.loading;
        var mensagem = Boolean(opcoes.mensagem) ? opcoes.mensagem : 'Aguarde ...';
        var delay    = Boolean(opcoes.delay) ? opcoes.delay : 0;
    }else if(typeof(opcoes)=='boolean'){
        var loading  = opcoes;
        var mensagem = 'Aguarde ...';
        var delay    = 0;
    }else {
        return this;
    }

    if(loading) {
        var loadingHtml = '<div class="__loading" style="transition: .5s ease; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);"><h3><i class="fas fa-sync fa-spin"></i> '+mensagem+'</h3></div>';
        var contentHtml = '<div class="__content" style="pointer-events: none;opacity: 0.4"></div>';
        $elemento.prepend('<div></div>');
        $elemento.wrapInner(contentHtml);
        $elemento.append(loadingHtml);
    }else {
        setTimeout(function(){
            $elemento.find('.__content').children().unwrap('.__content');
            $elemento.find('.__loading').remove();
        }, delay);
    }
    return this;
};
