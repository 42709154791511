
// Aplica para todos os formularios que tem a classe tabula-no-enter,
$(function () {

    function nextFocus(element) {
        var self = $(element)
            , form = self.parents('form:eq(0)')
            , focusable
            , next
        ;

        focusable = form.find('input,select,button').filter(':visible:not([disabled], [readonly], .sem-focus, [sem-focus])');
        next = focusable.eq(focusable.index(element) + 1);

        if(next.attr('type')==='text') {
            next.select().focus();
        }

        if (next.length) {
            next.select().focus();
        } else {
            form.submit();
        }
    }

    $.fn.tabulacaoNoEnter = function () {
        var enableKeyUp = true;

        // Cancela submit ao apertar enter em algun input, select ou textarea
        $(this).on('keydown', 'input:not(.multiselect__input, .desabilita-tab-enter), select', function(e) {
            if(e.keyCode == 13) {
                return false;
            }
        });

        // Configura foco no proximo input ao pressionar enter
        $(this).on('keyup', 'input, select', function(e) {

            if (e.ctrlKey && e.keyCode == 13) {
                $(this).parents('form:first').submit();
                return;
            }

            if (e.keyCode == 13 && enableKeyUp) {
                // Fecha datepicker
                // $('body').find('.mascara-campo-data').datepicker('hide');
                nextFocus(this);
            }else {
                enableKeyUp = true;
            }
        });

        // Selectpicker
        $(this).find('.selectpicker').on('hidden.bs.select', function (e) {
            nextFocus(this);
        });

        // Autocomplete
        $(this).on('change','.app-multiselect', function(e) {
            nextFocus($(this).find('.multiselect__input:first'));
            // enableKeyUp = false;
        });
    };

    $('#app form').not('.desabilita-tab-enter').tabulacaoNoEnter();

    // Foca primeiro input visível
    setTimeout(function () {
        $('#app form:first').find('input,select')
            .filter(':visible:not([type=hidden], [disabled], .sem-focus, [sem-focus]):first')
            .focus()
            .select();
    }, 200);
});
