<script>

    import { HorizontalBar, mixins } from 'vue-chartjs'

    export default {
        extends: HorizontalBar,

        mixins: [mixins.reactiveProp],

        props: ['chart-opcoes'],

        mounted () {
            var opcoes = null;

            if (this.chartOpcoes) {
                opcoes = Object.assign(this.opcoesPadrao, this.chartOpcoes);
            } else {
                opcoes = this.opcoesPadrao;
            }
            this.renderChart(this.chartData, opcoes);
        },

        data () {
            return {
                opcoesPadrao: {

                    scales: {
                        yAxes: [{
                            ticks: {
                                display: true,
                                beginAtZero: true,
                            }
                       }]
                    },

                    legend: {
                        display: false
                    },

                    responsive: true,

                    maintainAspectRatio: false,

                    plugins: {
                        datalabels: {
                            anchor: 'end',
                            align: 'end',
                            formatter: function(value, context) {
                                return value ;
                            },
                        },
                    },

                }
            }
        },
    }

</script>
