/**
 * app.js
 *
 * Destina-se à inclusão de todas as libs específicas utilizadas pela aplicação.
 * Evite envolver scripts de templates neste arquivo.
 * Evite declarar funções no escopo deste arquivo. Para isso, utilize o custom.js
 */

require('./app/mascaras.js');
require('./app/async-form.js');
require('./app/tabula-no-enter.js');
require('./app/btn-to-form.js');
require('./app/vue.js');
require('./app/emojis.js');
require('./app/custom.js');
