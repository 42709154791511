<template>

    <div class="row">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-8 offset-md-2">
                    <div class="form-group form-group-md">
                        <div class="input-group">
                            <span class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-file-invoice-dollar"></i></span>
                            </span>
                            <select ref="contratos"
                                    v-model="filtros.contrato_id"
                                    class="form-control text-center"
                                    title="Contrato"
                                    style="padding:0px;">
                                <option value="">
                                    TODOS OS CONTRATOS
                                </option>
                                <option v-bind:key="contrato.id" v-for="contrato in contratos" :value="contrato.id" v-html="contrato.label">
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <Chart tipo="Bar" :opcoes="opcoes" :url="url">
            </Chart>
        </div>
    </div>

</template>

<script>

    import Chart from '../Chart.vue';

    export default {

        components: {
            'Chart': Chart,
        },

        props: ['contratos-default'],

        data () {
            return {
                contratos: this.contratosDefault ? JSON.parse(this.contratosDefault) : [],

                filtros: {
                    contrato_id: ''
                },

                opcoes: {
                    legend: {
                        display: true
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                callback: function(value, index, values) {
                                    return formatarDinheiro(value, 'R$', 2, ',', '.');
                                },
                                beginAtZero: true,
                            }
                        }]
                    },
                    layout: {
                        padding: {
                            left: 50,
                            right: 50,
                            top: 20,
                            bottom: 30
                        }
                    },
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem, data) {
                                let label = data.datasets[tooltipItem.datasetIndex].label || '';

                                if (label) {
                                    label += ': ';
                                }

                                label += formatarDinheiro(tooltipItem.yLabel, 'R$', 2, ',', '.');

                                return label
                            }
                        }
                    }
                }
            }
        },

        computed: {
            url: function () {
                return '/contratos/bi/saldo-dos-empenhos?contrato_id=' + this.filtros.contrato_id;
            }
        },

        mounted () {
            // empty
        },

    }
</script>
