<script>

    import { Doughnut, mixins } from 'vue-chartjs'

    export default {
        extends: Doughnut,

        mixins: [mixins.reactiveProp],

        props: ['chart-opcoes'],

        mounted () {
            var opcoes = null;

            if (this.chartOpcoes) {
                opcoes = Object.assign(this.opcoesPadrao, this.chartOpcoes);
            } else {
                opcoes = this.opcoesPadrao;
            }

            this.renderChart(this.chartData, opcoes);
        },

        data () {
            return {
                opcoesPadrao: {
                    scales: {
                        yAxes: [{
                            display: true,
                            ticks: {
                                display: false,
                                beginAtZero: true,
                            }
                       }]
                    },

                    layout: {
                        padding: {
                            left: 60,
                            right: 0,
                            top: 60,
                            bottom: 65
                        }
                    },

                    responsive: true,

                    maintainAspectRatio: false,

                    animation: {
                        animateScale: true,
                        animateRotate: true
                    },

                    legend: {
                        display: false,
                        position: 'right',
                        // fullWidth: false,
                        labels: {
                            fontSize: 14,
                            fontColor: '#444'
                        }
                    },

                    plugins: {

                        datalabels: {
                            anchor: 'end',
                            align: 'start',
                            font: {
                                weight: 'bold'
                            },
                            formatter: function(value, context) {
                                var dataset = context.dataset;
                                var total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                                    return previousValue + currentValue;
                                });

                                var porcentagem = ((value/total) * 100).toFixed(2);

                                return porcentagem +'%';
                            }
                        },

                        outlabels: {
                            text: '%l',
                            percentPrecision: 2,
                            valuePrecision: 2,
                            // backgroundColor: "#444",
                            borderColor: "#444",
                            // lineColor: '#444',
                            borderRadius: 17,
                            color: '#444',
                            stretch: 45,
                            font: {
                                resizable: true,
                                minSize: 12,
                                maxSize: 18
                            },
                        }
                    },

                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem, data) {
                                var dataset = data.datasets[tooltipItem.datasetIndex];
                                var total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                                    return previousValue + currentValue;
                                });

                                var currentValue = dataset.data[tooltipItem.index];
                                var porcentagem = ((currentValue/total) * 100).toFixed(2);

                                var html = '';
                                html += '<dl class="dl-horizontal tooltip-custom">';
                                html +=     '<dt>Medicamento</dt>';
                                html +=     '<dd>' + data.labels[tooltipItem.index] + '</dd>';
                                html +=     '<dt>Quantidade</dt>';
                                html +=     '<dd class="text-left">' + currentValue + ' <small>(' + porcentagem + '%)</small></dd>';
                                html += '</dl>';
                                return html;
                            }
                        },

                        // Habilita html: https://www.chartjs.org/docs/latest/configuration/tooltip.html#external-custom-tooltips
                        enabled: false,
                        custom: function(tooltipModel) {
                            // Tooltip Element
                            var tooltipEl = document.getElementById('chartjs-tooltip');

                            // Create element on first render
                            if (!tooltipEl) {
                                tooltipEl = document.createElement('div');
                                tooltipEl.id = 'chartjs-tooltip';
                                tooltipEl.innerHTML = '<table></table>';
                                document.body.appendChild(tooltipEl);
                            }

                            // Hide if no tooltip
                            if (tooltipModel.opacity === 0) {
                                tooltipEl.style.opacity = 0;
                                return;
                            }

                            // Set caret Position
                            tooltipEl.classList.remove('above', 'below', 'no-transform');
                            if (tooltipModel.yAlign) {
                                tooltipEl.classList.add(tooltipModel.yAlign);
                            } else {
                                tooltipEl.classList.add('no-transform');
                            }

                            function getBody(bodyItem) {
                                return bodyItem.lines;
                            }

                            // Set Text
                            if (tooltipModel.body) {
                                var titleLines = tooltipModel.title || [];
                                var bodyLines = tooltipModel.body.map(getBody);

                                var innerHtml = '<thead>';

                                titleLines.forEach(function(title) {
                                    innerHtml += '<tr><th>' + title + '</th></tr>';
                                });
                                innerHtml += '</thead><tbody>';

                                bodyLines.forEach(function(body, i) {
                                    var colors = tooltipModel.labelColors[i];
                                    var style = 'background:' + colors.backgroundColor;
                                    style += '; border-color:' + colors.borderColor;
                                    style += '; border-width: 2px';
                                    var span = '<span style="' + style + '"></span>';
                                    innerHtml += '<tr><td>' + span + body + '</td></tr>';
                                });
                                innerHtml += '</tbody>';

                                var tableRoot = tooltipEl.querySelector('table');
                                tableRoot.innerHTML = innerHtml;
                            }

                            // `this` will be the overall tooltip
                            var position = this._chart.canvas.getBoundingClientRect();

                            // Display, position, and set styles for font
                            tooltipEl.style.opacity = 1;
                            tooltipEl.style.position = 'absolute';
                            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                            tooltipEl.style.pointerEvents = 'none';
                        }
                        // Fim habilita html

                    }
                }
            }
        }
    }

</script>

<style>

    .tooltip-custom {
        padding: 3px 8px;
        color: #fff;
        text-align: center;
        background-color: #333;
        border-radius: 4px;
    }

</style>
