
/**
 * Registra eventos nos botões/links com atributos data-method="delete|put"
 * o botão/link gera um formulário e executa um submit no form
 */
(function() {

 var laravel = {
     initialize: function() {
         this.registerEvents();
     },

     registerEvents: function() {
         $(document).on('click', 'a[data-method],button[data-method]', this.handleMethod);
     },

     handleMethod: function(e) {
         var link = $(this);
         var httpMethod = link.data('method').toUpperCase();
         var form;
         var callback = link.data('callback');
         var url = link.attr('href');

         // If the data-method attribute is not PUT, POST or DELETE,
         // then we don't know what to do. Just ignore.
         if ( $.inArray(httpMethod, ['PUT', 'POST', 'DELETE']) === - 1 ) {
             return;
         }

         // Allow user to optionally provide data-confirm="Are you sure?"
         var msgDeConfirmacao = 'Tem certeza?';
         if ( link.data('confirm') ) {
             msgDeConfirmacao = link.data('confirm');
         }

         swal({
                 title: msgDeConfirmacao,
                 dangerMode: true,
                 buttons: ['Não', 'Sim'],
                 confirm: {
                     closeModal: false
                 },
                 icon: "warning",
             }).then(function(confirm) {

                 if (confirm && callback) {

                     axios({
                             url: url,
                             method: httpMethod
                         })
                         .then(function () {
                             eval(callback);
                             swal('Ação concluída!', '', 'success');
                         })
                         .catch(function (e) {
                             swal('Não foi possível realizar ação', 'verifica sua conexão com à internet', 'error');
                         });

                 } else if (confirm) {
                     form = laravel.createForm(link);
                     form.submit();
                 }
             });

         e.preventDefault();
     },

     createForm: function(link) {
         var form =
             $('<form>', {
                 'method': 'POST',
                 'action': link.attr('href')
             });

         var token =
             $('<input>', {
                 'type': 'hidden',
                 'name': '_token',
                 'value': $('meta[name="csrf-token"]').attr('content')
             });

         var hiddenInput =
             $('<input>', {
                 'name': '_method',
                 'type': 'hidden',
                 'value': link.data('method')
             });

         return form.append(token, hiddenInput)
             .appendTo('body');
     }
 };

 laravel.initialize();

})();
