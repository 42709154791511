<script>

    import { Line, mixins } from 'vue-chartjs'

    export default {
        extends: Line,

        mixins: [mixins.reactiveProp],

        props: ['chart-opcoes'],

        mounted () {
            var opcoes = null;

            if (this.chartOpcoes) {
                opcoes = Object.assign(this.opcoesPadrao, this.chartOpcoes);
            } else {
                opcoes = this.opcoesPadrao;
            }

            this.renderChart(this.chartData, opcoes);
        },

        data () {
            return {
                opcoesPadrao: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                display: true,
                                beginAtZero: true,
                            }
                       }]
                    },

                    layout: {
                        padding: {
                            // left: 60,
                            right: 25,
                            // top: 60,
                            // bottom: 65
                        }
                    },

                    legend: {
                        display: false
                    },

                    responsive: true,

                    maintainAspectRatio: false,

                    plugins: {
                        datalabels: {
                            anchor: 'end',
                            align: 'start',
                        },
                    },

                }
            }
        },
    }

</script>
