<template>

    <div class="row">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-2 offset-md-4">
                    <div class="form-group form-group-md">
                        <label class="label-control">Início</label>
                        <div class="input-group">
                            <span class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="far fa-calendar-alt"></i>
                                </span>
                            </span>
                            <input v-model.lazy="filtros.inicio"
                                data-mask="00/00/0000"
                                data-mask-datepicker
                                class="form-control text-center"
                                title="Início">
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group form-group-md">
                        <label class="label-control">Fim</label>
                        <div class="input-group">
                            <span class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="far fa-calendar-alt"></i>
                                </span>
                            </span>
                            <input v-model.lazy="filtros.fim"
                                data-mask="00/00/0000"
                                data-mask-datepicker
                                class="form-control text-center"
                                title="Fim">
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!filtros.inicio || !filtros.fim" class="text-center text-warning">
                <div class="row">
                    <div class="col-md-6 offset-md-3">
                        <div class="alert alert-warning">
                            <i class="fas fa-exclamation-triangle"></i>
                            Selecione o período que deseja consultar!
                        </div>
                    </div>
                </div>
            </div>

            <Chart tipo="HorizontalBar" :opcoes="opcoes" :url="url">
            </Chart>
        </div>
    </div>

</template>

<script>

    import Chart from '../Chart.vue';

    export default {

        components: {
            'Chart': Chart,
        },

        data () {
            return {
                filtros: {
                    inicio: moment().startOf('year').format('DD/MM/YYYY'),
                    fim: moment().endOf('year').format('DD/MM/YYYY'),
                },

                opcoes: {
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [{
                            ticks: {
                                callback: function(value, index, values) {
                                    return formatarDinheiro(value, 'R$', 2, ',', '.');
                                }
                            },
                            scaleLabel: {
                                display: true,
                                labelString: 'Gasto médio diário per capita (R$)'
                            }
                        }]
                    },
                    layout: {
                        padding: {
                            left: 50,
                            right: 50,
                            top: 20,
                            bottom: 30
                        }
                    },
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem, data) {
                                return 'Gasto: (R$): ' + formatarDinheiro(tooltipItem.xLabel, 'R$', 2, ',', '.');
                            }
                        }
                    }
                }
            }
        },

        mounted () {
            // empty
        },

        computed: {
            url: function () {
                return `/merenda/bi/gasto-medio-diario-por-pessoa?inicio=${this.filtros.inicio}&fim=${this.filtros.fim}`;
            }
        },
    }
</script>
