<template>

    <div>
        <div class="chat-lista">
            <div class="card card-primary">
                <div class="card-header bg-secondary text-white" @click="alternarLista">
                    <b>
                        <i class="far fa-comments"></i> Chat

                        <span class="float-right" v-if="!conectado">
                            <i class="fa fa-circle offline"></i> offline
                        </span>
                        <span class="float-right" v-else>
                            <i class="fa fa-circle online"></i> online
                        </span>

                    </b>
                </div>

                <div class="card-body" v-show="mostrarLista">

                    <input class="form-control" placeholder="procurar" style="margin-left: 2.5%; width: 95%" ref="q" @keyup="filtrarUsuarios">

                    <div v-for="(contatosDoGrupo, nomeDoGrupo) in contatosPorGrupo">

                        <h6 v-if="nomeDoGrupo" class="text-primary"><b>{{ nomeDoGrupo }}</b></h6>

                        <ul class="list-unstyled contatos">
                            <li v-for="contato in contatosDoGrupo" @click="abrirConversaComOContato(contato)" v-show="contato.visivel">
                                <i class="fa fa-circle" :class="[ contato.online ? 'online' : 'offline' ]"></i>
                                <span class="account-user-avatar">
                                    <img :src="contato.foto" style="width: 26px; height: 26px;" alt="user-image" class="rounded-circle">
                                </span>
                                <b>{{ contato.nome }}</b>
                            </li>
                        </ul>

                    </div>
                </div>

                <div class="card-footer" v-show="mostrarLista">
                    <a :href="link_suporte" target="_blank" class="btn btn-primary btn-block btn-xs">Chat Suporte <i class="fas fa-external-link-alt"></i></a>
                </div>
            </div>
        </div>

        <conversa v-for="(conversa, index) in conversas"
                :conversa="conversa"
                :contatos="contatos"
                :usuario_id="usuario_id"
                :chat_chave_criptografia="chat_chave_criptografia"
                :chat_chave_de_sessao="chat_chave_de_sessao"
                :index="index"
                :key="conversa.id"
                @fechar="fecharConversa">
        </conversa>
    </div>

</template>

<script>

    export default {
        props: {
            usuario_id: Number,
            link_suporte: String,
            chat_chave_de_sessao: String,
            chat_chave_criptografia: String,
        },

        data () {
            return {
                mostrarLista: false,
                conversas: [],
                contatos: [],
                q: '',
            }
        },

        mounted () {

            if(!this.conectado) {
                console.log('Não conectou no chat');
                return;
            }

            if(window.Notification && Notification.permission !== "denied") {
            	Notification.requestPermission(function(status) {});
            }

            this.inicializarChat();

        },
        computed: {
            contatosPorGrupo () {
                var contatos = _.orderBy(this.contatos, ['unidade', 'online', 'nome'], ['asc', 'desc', 'asc']);
                return _.groupBy(contatos, 'unidade');
            },
            conectado () {
                return !! window.Echo;
            }
        },
        methods: {

            filtrarUsuarios () {
                var q = this.$refs.q.value;

                _.forEach(this.contatos, (contato) => {
                    contato.visivel = !q || contato.nome.toLowerCase().indexOf(q.toLowerCase()) >= 0;
                });
            },

            alternarLista () {
                this.mostrarLista = !this.mostrarLista;

                this.salvarEstadoDoChat();
            },

            abrirConversaComOContato (contato) {
                let self = this;

                axios.get('/chat/conversas/buscar_conversa_direta_com_o_participante/' + contato.id)
                    .then(function (response) {
                        self.abrirConversa(response.data);
                    })

            },

            abrirConversa (conversa) {
                if(_.find(this.conversas, {id: conversa.id}))
                    return;

                conversa.mensagens = [];
                this.conversas.push(conversa);

                this.salvarEstadoDoChat();
            },

            mostrarNotificacao(mensagem) {
                var n = new Notification('Chat - ' + mensagem.remetente.nome, {
                    body: mensagem.texto,
                    icon: '/img/icone-notificacao-chat.png'
                });

                n.onclick = function () {
                  parent.focus();
                  window.focus(); //just in case, older browsers
                  this.close();
                };

                setTimeout(n.close.bind(n), 4000);
            },

            fecharConversa (conversa, index) {
                this.conversas.splice(index, 1);

                this.salvarEstadoDoChat();
            },

            salvarEstadoDoChat () {
                var estado = JSON.stringify({
                    mostrarLista: this.mostrarLista,
                    conversas: this.conversas,
                });

                var hash = encrypt(estado, this.chat_chave_criptografia);
                localStorage.setItem(this.chat_chave_de_sessao, hash);
            },

            inicializarChat () {
                let self = this;

                axios.get('/chat/contatos')
                    .then((response) => {

                        response.data.forEach((contato) => {
                            contato.online = false;
                            contato.visivel = true;
                        });

                        self.contatos = response.data;

                        var estado = localStorage.getItem(self.chat_chave_de_sessao);

                        if(estado) {
                            estado = JSON.parse(decrypt(estado, self.chat_chave_criptografia));

                            self.mostrarLista = estado.mostrarLista;

                            estado.conversas.forEach((conversa) => {
                                self.abrirConversa(conversa);
                            });
                        } else {
                            // limpa estados anteriores da local storage
                            _.keys(localStorage).forEach((chave) => {
                                if(_.includes(chave, 'chat_')) {
                                    localStorage.removeItem(chave);
                                }
                            });
                        }

                        self.entrarNaSalaDeChat();
                        self.salvarEstadoDoChat();
                    })
                    .catch(function (e) {
                        console.log(e);
                    });
            },

            entrarNaSalaDeChat () {
                self = this;

                // Monitora mensagens
                Echo.private('chat.' + this.usuario_id + '.mensagens')
                    .listen('.nova', (e) => {

                        var conversa = _.find(self.conversas, {id: e.conversa.id})
                        if (conversa) {
                            conversa.mensagens.push(e);
                        } else {
                            self.abrirConversa(e.conversa);
                        }
                    });

                // Monitora contatos
                Echo.join('chat.contatos')
                    .here((contatosOnline) => {
                        contatosOnline.forEach((contatoOnline) => {
                            var contato = _.find(self.contatos, {id: contatoOnline.id});

                            if(contato) {
                                contato.online = true;
                            }
                        });

                        self.salvarEstadoDoChat();
                    })
                    .joining((contatoEntrando) => {
                        var contato = _.find(self.contatos, {id: contatoEntrando.id});

                        clearTimeout(contato.timeoutSaindo);

                        if(contato) {
                            contato.online = true;
                        }

                        self.salvarEstadoDoChat();
                    })
                    .leaving((contatoSaindo) => {
                        var contato = _.find(self.contatos, {id: contatoSaindo.id});

                        if(contato) {

                            // aguarda 3 segundos antes de definir o usuário como offline
                            contato.timeoutSaindo = setTimeout(() => {
                                contato.online = false;
                                this.salvarEstadoDoChat();
                            }, 3000);
                        }

                    });
            }
        }
    }
</script>

<style scoped>

    .chat-lista {
        width: 280px;
        position: fixed;
        bottom: 0;
        right: 20px;
        z-index: 10;
    }

    .card {
        margin: 0;
    }

    .card-header {
        cursor: pointer;
        padding: 10px;
    }

    .card-body {
        padding: 0px;
        padding-top: 15px;
        height: 350px;
        overflow-y: auto;
    }

    .card-footer {
        border-top: none;
    }

    .card-body.active {
        padding: 0;
    }

    .card-body hr {
        margin: 0;
    }

    .card-body h6 {
        margin-left: 10px;
        font-size: 0.8em;
    }

    .contatos {
    }

    .contatos li {
        padding: 5px 5px;
        cursor: pointer;
        font-size: 12px;
    }

    .contatos li:hover {
        background-color: #eee;
    }

    .fa-circle.online {
        color: #55da55;
    }

    .fa-circle.offline {
        color: #cac7c7;
    }

</style>
