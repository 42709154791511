|<template>

    <div ref="panelChart">

        <div v-if="loading" style="z-index: 99; transition: .5s ease; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);">
            <div style="background-color: #fff; border: 5px solid #ddd; padding: 20px;">
                <h3><i class="fas fa-sync fa-spin"></i> Carregando ...</h3>
            </div>
        </div>

        <div v-else-if="falha">
            <br>
            <h2 class="text-center">
                <i class="fas fa-exclamation-triangle"></i> Falha na conexão, não foi possível baixar dados!
            </h2>
            <br>
            <div class="text-center">
                <p class="lead">Verifique sua conexão com à internet e tente novamente.</p>
                <button @click="buscarDados" type="button" class="btn btn-primary btn-md"> <i class="fas fa-sync"></i> Tentar novamente!</button>
            </div>
        </div>

        <div v-if="chartDataVazio">
            <br>
            <h2 class="text-center">
                Nenhum dado disponível.
            </h2>
            <br>
            <div class="text-center">
                <p class="lead">A consulta não retornou nenhum dado.</p>
            </div>
            <br>
        </div>


        <DoughnutChart v-else-if="chartData && tipo == 'Doughnut'"
            :chart-opcoes="opcoes"
            :chart-data="chartData">
        </DoughnutChart>

        <BarChart v-else-if="chartData && tipo == 'Bar'"
            :chart-opcoes="opcoes"
            :chart-data="chartData">
        </BarChart>

        <HorizontalBarChart v-else-if="chartData && tipo == 'HorizontalBar'"
            :chart-opcoes="opcoes"
            :chart-data="chartData"
            :styles="horizontalBarStyles">
        </HorizontalBarChart>

        <LineChart v-else-if="chartData && tipo == 'Line'"
            :chart-opcoes="opcoes" 
            :chart-data="chartData">
        </LineChart>

    </div>

</template>


<script>
    import DoughnutChart from './DoughnutChart.vue';
    import BarChart from './BarChart.vue';
    import HorizontalBarChart from './HorizontalBarChart.vue';
    import LineChart from './LineChart.vue';

    export default {

        components: {
            'LineChart': LineChart,
            'BarChart': BarChart,
            'HorizontalBarChart': HorizontalBarChart,
            'DoughnutChart': DoughnutChart,
        },

        props: ['tipo', 'url', 'opcoes'],

        data () {
            return {
                chartData: false,
                falha: false,
                loading: false,
            }
        },

        watch: {
            url: function () {
                this.buscarDados();
            }
        },

        mounted () {
            this.buscarDados();
        },

        methods: {
            buscarDados: function () {

                let self = this;

                this.falha = false;
                this.loading = true;

                axios.get(this.url)
                    .then(function (response) {
                        self.loading = false;
                        self.chartData = response.data;
                    })
                    .catch(function (fail) {
                        self.falha = true;
                        self.loading = false;
                    });
            },

            atualizarDados: function () {
                this.buscarDados();
            }
        },

        computed: {
            // Calcula o tamanho do canvas quando o chart for horizontal
            // para evitar overflow ou sobreposição dos itens
            horizontalBarStyles () {
                let height = 400;

                try {
                    height = 80 + this.chartData.labels.length * 22 * this.chartData.datasets.length;
                } catch (error) {
                    //
                }

                return {
                    height: `${height}px`
                }
            },

            chartDataVazio() {
                let chartDataVazio;

                try {
                    chartDataVazio = this.chartData.labels.length <= 0;
                } catch {
                    chartDataVazio = false;
                }

                return chartDataVazio;
            }
        }
    }
</script>
