<template>

    <div :id="id" ref="modal" class="modal fade" role="dialog">
        <div :class="'modal-dialog ' + modalSize">
            <div class="modal-content">

                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h4 class="modal-title">

                        <!-- SLOT HEADER -->
                        <div name="header"></div>

                    </h4>
                </div>

                <form ref="formulario">

                    <input type="hidden" name="_method" :value="method.toUpperCase()">
                    <input type="hidden" name="_token" :value="token">

                    <div class="modal-body">

                        <div v-show="mostrarMensagemDeErro" class="alert alert-danger" role="alert">
                            <button type="button" class="close" @click="mensagemDeErro = null"><span>&times;</span></button>
                            <span v-html="mensagemDeErro"></span>
                        </div>

                        <!--  SLOT BODY -->
                        <slot></slot>

                    </div>

                    <div class="modal-footer">

                        <!-- SLOTE FOOTER -->
                        <div name="footer">
                        </div>

                        <button type="button" class="btn btn-danger" data-dismiss="modal" v-html="btnCancelText"></button>
                        <button ref="btnConfirm" type="submit" class="btn btn-primary" v-html="btnConfirmText"></button>
                    </div>

                </form>

            </div>
        </div>
    </div>

</template>

<script>

    export default {

        props: {
            'id': {type: String, required: true},
            'action': {type: String, required: true},
            'method': {type: String, required: false, default: 'POST'},
            'btn-cancel-text': {type: String, required: false, default: "<i class='fa fa-times'></i> Cancelar"},
            'btn-confirm-text': {type: String, required: false, default: "Confirmar"},
            'modal-size': {type: String, required: false, default: 'modal-md'},
        },

        data () {
            return {
                mensagemDeErro: null,
                token: $('meta[name="csrf-token"]').attr('content'),
            }
        },

        computed: {
            mostrarMensagemDeErro () {
                return this.mensagemDeErro != null && this.mensagemDeErro != '';
            }
        },

        mounted () {
            var self = this;

            $(this.$refs.formulario).submit((e) => {
                e.preventDefault();
                self.enviarFormulario();
            });

            // $(this.$refs.modal).modal('show');
        },

        methods: {

            enviarFormulario () {
                var self = this;
                var $form = $(this.$refs.formulario);
                var $modal = $(this.$refs.modal);
                var $btnConfirm = $(self.$refs.btnConfirm);

                var dados = $form.serializeArray();
                self.mensagemDeErro = null;

                $btnConfirm.attr('disabled', true)
                    .addClass('disabled')
                    .html('<i class="fa fa-sync-alt fa-spin"></i> Aguarde ...')

                $.ajax({
                        url: this.action,
                        method: this.method,
                        data: dados,
                    })
                    .done(function (response) {
                        self.$emit('on-success', $modal);
                        self.mensagemDeErro = null;
                    })
                    .fail(function (error) {
                        self.mostrarFalha(error);
                    })
                    .always(function () {
                        $(self.$refs.btnConfirm).attr('disabled', false)
                            .removeClass('disabled')
                            .html(self.btnConfirmText);
                    });
            },

            mostrarFalha (error) {

                if (typeof error === 'string') {
                    this.mensagemDeErro = error;
                    return;
                }

                switch (error.status) {

                    case 422:
                    case 400:
                        if (typeof error.responseJSON === 'string') {
                            msg +=    error.responseJSON;
                        } else {
                            var msg = '';
                            $.each(error.responseJSON, function (item) {
                                msg += error.responseJSON[item] + '<br>';
                            });
                            this.mensagemDeErro = msg;
                        }
                        return;

                    case 500:
                        this.mensagemDeErro = 'Falha interna do servidor';
                        return;

                    case 403:
                        this.mensagemDeErro = 'Não autorizado!';
                        return;

                    default:
                        this.mensagemDeErro = error.responseJSON;
                }
            }

        }
    }

</script>
