/**
 * vue.js
 *
 * Destina-se à inclusão de códigos que envolvam vue e seus componentes.
 */

window.Vue = require('vue');

 /**
  * O seguinte trecho de código pode ser utilizado para registrar automaticamente
  * os componentes Vue. O código irá varrer o diretório em busca de componentes
  * Vue e registra-los baseado em seu nome de arquivo em kebab-case
  *
  * Ex: ./components/ExampleComponent.vue -> <example-component></example-component>
  *
  */
const files = require.context('../components/', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

/**
 * Em seguida, será criada uma nova instância Vue, que será atrelada à página
 * (os templates devem possuir uma div global com o id="app"). Registre os
 * componentes vue em resources/js/components
 */
window.app = new Vue({
    el: '#app',
});
