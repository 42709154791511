<template>

    <div class="row">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-4 offset-md-3">
                    <div class="form-group form-group-md">
                        <label class="label-control">Escola</label>
                        <div class="input-group">
                            <span class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="fas fa-school"></i>
                                </span>
                            </span>
                            <select ref="escolas"
                                    v-model="filtros.escola_id"
                                    class="form-control text-center"
                                    title="Escola"
                                    style="padding: 0px;">
                                <option value="">Selecione a escola</option>
                                <option v-bind:key="escola.id" v-for="(escola) in escolas" :value="escola.id" v-html="escola.label">
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group form-group-md">
                        <label class="label-control">Ano</label>
                        <div class="input-group">
                            <span class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="far fa-calendar-alt"></i>
                                </span>
                            </span>
                            <select ref="ano"
                                    v-model="filtros.ano"
                                    class="form-control text-center"
                                    title="Ano"
                                    style="padding: 0px;">
                                <option v-bind:key="ano" v-for="ano in anos" :value="ano" v-html="ano">
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!filtros.escola_id" class="text-center text-warning">
                <div class="row">
                    <div class="col-md-6 offset-md-3">
                        <div class="alert alert-warning">
                            <i class="fas fa-exclamation-triangle"></i>
                            Selecione a escola que deseja consultar!
                        </div>
                    </div>
                </div>
            </div>

            <Chart tipo="Line" :opcoes="opcoes" :url="url">
            </Chart>
        </div>
    </div>

</template>

<script>

    import Chart from '../Chart.vue';

    export default {

        components: {
            'Chart': Chart,
        },

        props: ['escolas-default'],

        data () {
            return {
                escolas: this.escolasDefault ? JSON.parse(this.escolasDefault) : [],

                filtros: {
                    ano: this.anoAtual(),
                    escola_id: ''
                },

                opcoes: {
                    legend: {
                        display: false
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                callback: function(value, index, values) {
                                    return formatarDinheiro(value, 'R$', 2, ',', '.');
                                }
                            },
                            scaleLabel: {
                                display: true,
                                labelString: 'Gastos (R$)'
                            }
                        }],
                        xAxes: [{
                            ticks: {

                            },
                            scaleLabel: {
                                display: true,
                                labelString: 'Meses'
                            }
                        }]
                    },
                    layout: {
                        padding: {
                            left: 50,
                            right: 50,
                            top: 20,
                            bottom: 30
                        }
                    },
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem, data) {
                                return 'Gasto: ' + formatarDinheiro(tooltipItem.yLabel, 'R$', 2, ',', '.');
                            }
                        }
                    }
                }
            }
        },

        mounted () {
            // empty
        },

        computed: {
            anos: function () {
                return _.range(2019, this.anoAtual() + 1);
            },

            url: function () {
                return `/merenda/bi/gasto-anual-por-escola?ano=${this.filtros.ano}&escola_id=${this.filtros.escola_id}`;
            }
        },

        methods: {
            anoAtual() {
                return moment().year();
            },
        },

    }
</script>
