<script>

    import { Bar, mixins } from 'vue-chartjs'

    export default {
        extends: Bar,

        mixins: [mixins.reactiveProp],

        props: ['chart-opcoes'],

        mounted () {
            var opcoes = null;

            if (this.chartOpcoes) {
                opcoes = Object.assign(this.opcoesPadrao, this.chartOpcoes);
            } else {
                opcoes = this.opcoesPadrao;
            }

            this.renderChart(this.chartData, opcoes);
        },

        data () {
            return {
                opcoesPadrao: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                display: true,
                                beginAtZero: true,
                            }
                       }]
                    },

                    legend: {
                        display: false
                    },

                    responsive: true,

                    maintainAspectRatio: false,
                }
            }
        },
    }

</script>
