<template>
    <div class="row">
        <div class="col-md-12">
            <Chart tipo="Bar" :opcoes="opcoes" url="/contratos/bi/valor-dos-empenhos-por-contrato">
            </Chart>
        </div>
    </div>

</template>

<script>

    import Chart from '../Chart.vue';

    export default {

        components: {
            'Chart': Chart,
        },

        data () {
            return {
                opcoes: {
                    legend: {
                        display: true
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                callback: function(value, index, values) {
                                    return formatarDinheiro(value, 'R$', 2, ',', '.');
                                }
                            }
                        }]
                    },
                    layout: {
                        padding: {
                            left: 50,
                            right: 50,
                            top: 20,
                            bottom: 30
                        }
                    },
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem, data) {
                                let label = data.datasets[tooltipItem.datasetIndex].label || '';

                                if (label) {
                                    label += ': ';
                                }

                                label += formatarDinheiro(tooltipItem.yLabel, 'R$', 2, ',', '.');

                                return label
                            }
                        }
                    }
                }
            }
        },

        mounted () {
            // empty
        },

    }
</script>
