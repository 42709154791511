<template>

    <div class="app-multiselect" ref="appMultiselect" :id="id">

        <multiselect
            :placeholder="placeholderCalculado"
            select-label=""
            deselect-label=""
            :track-by="campoValor"
            :label="campoLabel"
            v-model="value"
            :options="options"
            :internal-search="internalSearch"
            @search-change="buscar"
            @select="valorMudou"
            :max-height="1000"
            :clear-all-button="true"
            :clear-button="true"
            open-direction="bottom"
            :loading="loading"
            :hide-selected="true"
            selected-label="selecionado"
            :close-on-select="!internalSearch"
            :multiple="multiplo"
            :disabled="desabilitado">


            <template slot="option" slot-scope="props">

                <slot :opcao="props.option">
                    {{ props.option[campoLabel] }}
                </slot>

            </template>

            <span slot="noResult">Nada encontrado</span>

            <span slot="noOptions" class="nao-mostrar">Nada encontrado</span>

        </multiselect>

        <template v-if="multiplo && valorInput.length == 0">
            <input type="hidden" :name="name + '[]'" value="">
        </template>

        <template v-if="multiplo">
                <input v-for="valor in valorInput" type="hidden" v-if="value" :name="name + '[]'" :value="valor">
        </template>

        <template v-else>
            <input type="hidden" :name="name" :value="valorInput">
        </template>

    </div>

</template>

<script>
    import Multiselect from 'vue-multiselect'

    /**
     * Segue os eventos e listeners deste componente
     *
     * Eventos:
     *      event-autocomplete-changevalue-<id_do_componente>
     * Listens:
     *      event-autocomplete-clear-<id_do_componente>
     *      event-autocomplete-setvalue-<id_do_componente>
     */
    export default {
        components: {
            Multiselect
        },

        props: ['url', 'placeholder', 'name', 'valor-default', 'campo-valor', 'campo-label', 'required', 'disabled', 'options-default', 'size', 'add-class', 'multiplo', 'id'],

        data () {
            return {
                value: this.valorDefault && _.isString(this.valorDefault) ? JSON.parse(this.valorDefault) : this.valorDefault,
                options: this.optionsDefault ? JSON.parse(this.optionsDefault): [],
                internalSearch: !!this.optionsDefault,
                loading: false,
                desabilitado: this.disabled,
            }
        },
        mounted: function () {
            var self = this;

            if (this.id) {
                this.$root.$on('event-autocomplete-clear-' + this.id, this.limpar);
                this.$root.$on('event-autocomplete-setvalue-' + this.id, function (value) {
                    self.value = value;
                });
            }


            if(this.size == 'sm') {
                $(this.$el).addClass('app-multiselect-sm');
            }

            if (!this.multiplo) {
                $(this.$el).addClass('single');
            }

            if(this.size == 'lg') {
                $(this.$el).addClass('app-multiselect-lg');
            }

            if(this.required && !this.valorDefault) {
                $(this.$refs.appMultiselect).find('.multiselect__input').prop('required', true);
            }

            if(this.addClass !== '') {
                $(this.$refs.appMultiselect).find('.multiselect__input').addClass(this.addClass);
            }

            $(this.$refs.appMultiselect).on('disable', function () {
                self.desabilitado = true;
            });

            $(this.$refs.appMultiselect).on('enable', function () {
                self.desabilitado = false;
            });

        },
        computed: {
            valorInput: function() {

                if(this.required) {
                    $(this.$refs.appMultiselect).find('.multiselect__input').prop('required', true);
                }

                if (!this.value) {
                    return '';
                }

                if($.type(this.value) === 'object') {
                    $(this.$refs.appMultiselect).find('.multiselect__input').prop('required', false);
                    return this.value[this.campoValor];
                }

                if ($.type(this.value) === 'array') {
                    let valores = [];
                    let campoValor = this.campoValor;

                    $.each(this.value, function (i, valor) {
                        valores.push(valor[campoValor]);
                    });

                    if (valores.length > 0) {
                        $(this.$refs.appMultiselect).find('.multiselect__input').prop('required', false);
                    }

                    return valores;
                }

                return '';
            },
            placeholderCalculado () {
                if(this.value && !this.multiplo) {
                    return this.value[this.campoLabel];
                }

                return this.placeholder;
            }
        },
        methods: {
            buscar: _.debounce(function(query) {

                if (!this.internalSearch) {
                    this.loading = true;

                    axios.get(this.url, {params: {q: query, autocomplete: 1}}).then(response => {

                        if(!response.data) {
                            this.options = [];
                        } else {
                            this.options = response.data;
                        }

                        this.loading = false;
                    });
                }
            }, 700),

            valorMudou: function() {
                var self = this;

                if (!this.internalSearch) {
                    self.limparOptions();
                }

                setTimeout(function() {
                    self.$emit('input', self.value, self.id);
                    $('[name="' + self.name + '"]').trigger('change');
                    self.$root.$emit('event-autocomplete-changevalue-' + self.id, self.value);
                }, 200);
            },

            limparOptions () {
                this.options = [];
            },

            limpar () {
                this.value = null;
                this.$emit('input', null);
                this.options = [];

                if(this.required && !this.valorDefault) {
                    $(this.$refs.appMultiselect).find('.multiselect__input').prop('required', true);
                }
            }
        }
    }
</script>

<style>

    .app-multiselect .multiselect--active {
        z-index: 5;
    }

    .app-multiselect .multiselect {
        min-height: 30px;
    }

    .app-multiselect .multiselect__select {
        min-height: 30px;
        top: 8px;
    }

    .app-multiselect.single .multiselect__tags {
        height: 38px;
    }

    .app-multiselect .multiselect--active .multiselect__tags {
        border-color: #98cbe8;
        outline: 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(152, 203, 232, 0.6);
    }

    .multiselect__tags {
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        min-height: 30px!important;
    }

    .app-multiselect .multiselect__content li:last-child {
        display:none;
    }

    /* small */
    .app-multiselect .multiselect__input {
        margin-bottom: 6px;
    }

    .app-multiselect-sm .multiselect__input {
        font-size: 12px;
    }

    .app-multiselect-sm .multiselect {
        min-height: 25px;
    }

    .app-multiselect-sm .multiselect__select {
        height: 30px;
    }

    .app-multiselect-sm .multiselect__input {
        margin-bottom: 0px;
    }

    .app-multiselect-sm .multiselect__tags {
        border-radius: 3px;
        padding-top: 3px;
    }
    <!-- -->
    .app-multiselect-lg .multiselect__input {
        height: 46px;
        padding: 10px 16px;
        font-size: 18px;
        line-height: 1.3333333;
        border-radius: 6px;
    }

    .app-multiselect-lg .multiselect {
        min-height: 46px;
    }

    .app-multiselect-lg .multiselect__select {
        height: 46px;
    }

    .app-multiselect-lg .multiselect__input {
        margin-bottom: 0px;
    }

    .app-multiselect-lg .multiselect__tags {
        height: 46px;
        padding: 10px 16px;
        font-size: 18px;
        line-height: 1.3333333;
        border-radius: 6px;
    }


</style>
